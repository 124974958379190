import { DynamoEmployer } from '../../../shared/models/dynamo-employer.model';
import { IParameters, Subscriber, Employer } from './accounts.state';

export const filterEmployersByShowing = (arrItems, params: IParameters): Employer[] => {
  let filteredRecords;
  switch (params.showing) {
    case 'all':
      filteredRecords = arrItems;
      break;
    default:
      filteredRecords = arrItems.filter((item: Employer) => {
        return item.state === params.showing;
      });
      break;
  }

  return filteredRecords;
};

export const sortEmployers = (arrItems, params: IParameters) => {
  const sortField = params.sortField;
  const sortDirection = params.sortDirection;
  return arrItems.sort((a: Employer, b: Employer) => {
    let textA;
    let textB;

    switch (sortField) {
      case 'attached':
        if (
          (a.state === 'final' && b.state === 'final') ||
          (a.state === 'final2' && b.state === 'final2')
        ) {
          textA = a['chapter']['order'] ? a['chapter']['order'] : null;
          textB = b['chapter']['order'] ? b['chapter']['order'] : null;
        } else {
          textA = a['en']['employer'] ? a['en']['employer'] : null;
          textB = b['en']['employer'] ? b['en']['employer'] : null;
        }
        break;
      case 'employer':
        textA = a['en']['employer'] ? a['en']['employer'] : null;
        textB = b['en']['employer'] ? b['en']['employer'] : null;
        break;
      case 'current_employees':
      case 'test_score':
      case 'elapsed_time':
      case 'created_at':
      case 'expires_at':
        textA = a[sortField] ? a[sortField] : null;
        textB = b[sortField] ? b[sortField] : null;
        break;
      default:
        textA = a[sortField] ? a[sortField].toUpperCase() : '';
        textB = b[sortField] ? b[sortField].toUpperCase() : '';
    }

    if (sortDirection === 'asc') {
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    }
    return textA > textB ? -1 : textA > textB ? 1 : 0;
  });
};

export const upsertAccount = (items: any[], newItem: DynamoEmployer) => {
  const existing = items.find((item) => item.id === newItem.id);

  if (existing) {
    return items.map((item) => (item.id === newItem.id ? newItem : item));
  }

  return [...items, newItem];
};

// Only update if found
export const updateAccount = (items: any[], newItem: DynamoEmployer) => {
  if (!items) {
    items = [];
  }

  const existing = items.find((item) => item.id === newItem.id);

  if (existing) {
    return items.map((item) => (item.id === newItem.id ? newItem : item));
  }

  return [...items];
};

export const filterSubscribersByShowing = (arrItems, params: IParameters): Subscriber[] => {
  let filteredRecords;
  // switch (params.showing) {
  //   case 'all':
  //     filteredRecords = arrItems;
  //     break;
  //   default:
  //     filteredRecords = arrItems.filter((item: Subscriber) => {
  //       return item.type === params.showing;
  //     });
  //     break;
  //
  // }
  filteredRecords = arrItems;
  return filteredRecords;
};

export const sortSubscribers = (arrItems, params: IParameters) => {
  const sortField = params.sortField;
  const sortDirection = params.sortDirection;
  return arrItems.sort((a: Subscriber, b: Subscriber) => {
    let textA;
    let textB;

    switch (typeof a[sortField]) {
      case 'number':
        textA = a[sortField] ? a[sortField] : null;
        textB = b[sortField] ? b[sortField] : null;
        break;
      case 'string':
        textA = a[sortField] ? a[sortField].toUpperCase() : '';
        textB = b[sortField] ? b[sortField].toUpperCase() : '';
        break;
      default:
        return 1;
    }

    if (sortDirection === 'asc') {
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    }
    return textA > textB ? -1 : textA > textB ? 1 : 0;
  });
};

export const upsertSubscriber = (items: Subscriber[], newItem: Subscriber) => {
  const existing = items.find((item) => item.id === newItem.id);

  if (existing) {
    return items.map((item) => (item.id === newItem.id ? newItem : item));
  }

  return [...items, newItem];
};
