import { Course, IParameters, Page, Question } from './courses.state';

export const filterQuestionsByShowing = (arrItems, params: IParameters): Question[] => {
  let filteredRecords;
  switch (params.showing) {
    case 'all':
      filteredRecords = arrItems;
      break;
    default:
      filteredRecords = arrItems.filter((item: Question) => {
        return item.type === params.showing;
      });
      break;
  }

  return filteredRecords;
};

export const sortQuestions = (arrItems, params: IParameters) => {
  const sortField = params.sortField;
  const sortDirection = params.sortDirection;
  return arrItems.sort((a: Question, b: Question) => {
    let textA;
    let textB;

    switch (sortField) {
      case 'attached':
        if (
          (a.type === 'final' && b.type === 'final') ||
          (a.type === 'final2' && b.type === 'final2')
        ) {
          textA = a['chapter']['order'] ? a['chapter']['order'] : null;
          textB = b['chapter']['order'] ? b['chapter']['order'] : null;
        } else {
          textA = a['en']['question'] ? a['en']['question'] : null;
          textB = b['en']['question'] ? b['en']['question'] : null;
        }
        break;
      case 'question':
        textA = a['en']['question'] ? a['en']['question'] : null;
        textB = b['en']['question'] ? b['en']['question'] : null;
        break;
      case 'test_score':
      case 'elapsed_time':
      case 'created_at':
      case 'expires_at':
        textA = a[sortField] ? a[sortField] : null;
        textB = b[sortField] ? b[sortField] : null;
        break;
      default:
        textA = a[sortField] ? a[sortField].toUpperCase() : '';
        textB = b[sortField] ? b[sortField].toUpperCase() : '';
    }

    if (sortDirection === 'asc') {
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    }
    return textA > textB ? -1 : textA > textB ? 1 : 0;
  });
};

export const upsertQuestion = (items: Question[], newItem: Question) => {
  const existing = items.find((item) => item.id === newItem.id);

  if (existing) {
    return items.map((item) => (item.id === newItem.id ? newItem : item));
  }

  return [...items, newItem];
};

export const filterPagesByShowing = (arrItems, params: IParameters): Page[] => {
  let filteredRecords;
  // switch (params.showing) {
  //   case 'all':
  //     filteredRecords = arrItems;
  //     break;
  //   default:
  //     filteredRecords = arrItems.filter((item: Page) => {
  //       return item.type === params.showing;
  //     });
  //     break;
  //
  // }
  filteredRecords = arrItems;
  return filteredRecords;
};

export const sortPages = (arrItems, params: IParameters) => {
  const sortField = params.sortField;
  const sortDirection = params.sortDirection;
  return arrItems.sort((a: Page, b: Page) => {
    let textA;
    let textB;

    switch (typeof a[sortField]) {
      case 'number':
        textA = a[sortField] ? a[sortField] : null;
        textB = b[sortField] ? b[sortField] : null;
        break;
      case 'string':
        textA = a[sortField] ? a[sortField].toUpperCase() : '';
        textB = b[sortField] ? b[sortField].toUpperCase() : '';
        break;
      default:
        return 1;
    }

    if (sortDirection === 'asc') {
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    }
    return textA > textB ? -1 : textA > textB ? 1 : 0;
  });
};

export const upsertPage = (items: Page[], newItem: Page) => {
  const existing = items.find((item) => item.id === newItem.id);

  if (existing) {
    return items.map((item) => (item.id === newItem.id ? newItem : item));
  }

  return [...items, newItem];
};

export const upsertCourse = (items: Course[], newItem: Course) => {
  const existing = items.find((item) => item.id === newItem.id);

  if (existing) {
    return items.map((item) => (item.id === newItem.id ? newItem : item));
  }

  return [...items, newItem];
};
